import "./styles/global.css";

//ページ遷移でスクロールをTOPに戻す
export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const currentPosition = getSavedScrollPosition(location);
  window.scrollTo(...(currentPosition || [0, 0]));
  if (location.hash) {
    const targetId = location.hash.substring(1); // #を取り除く
    const target = document.getElementById(targetId);

    if (target) {
      setTimeout(() => {
        target.focus({ preventScroll: true });
      }, 0);
    }
  }
  return false;
}
