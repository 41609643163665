module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bros.studio/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Studio BROS","short_name":"Studio BROS","start_url":"/","background_color":"#061729","theme_color":"#000","display":"standalone","icon":"static/logo_triangle.png","icons":[{"src":"static/maskable_icon_x192.png","sizes":"192x192","type":"image/png"},{"src":"static/maskable_icon_x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"maskable"},"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b33607b8929dba395b27a388f945f5ba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-132,"duration":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["ja"],"defaultLanguage":"ja","siteUrl":"/","i18nextOptions":{"fallbackLng":"ja","supportedLngs":["ja"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(max-width: 480px)","tablet":"(max-width: 960px)","pc":"(max-width: 1200px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
